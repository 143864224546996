import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import a1 from './Component/images/a1.png';
import a2 from './Component/images/a2.png';
import a3 from './Component/images/a3.png';
import a4 from './Component/images/a4.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className="container mx-auto mt-8">
        <p className="text-2xl font-bold mb-4 text-center">H&W Alarm is a family-owned and locally run business since 1979. </p>
        <p className="mb-4 text-[20px] text-center">
          Focused on building strong relationships with each of our clients, our team aims to always give you a professional and courteous service experience. We believe that your security and fire solutions should be uncomplicated and provide you with tremendous peace of mind—for your family and your business. </p>
      </div>
      <section className=' border-t border-slate-700 px-[5%] mt-5'>
        <div className='pt-4'>
          <div class="row flex content-center">
            <div class="col-md-3 col-sm-3 col-3">
              <img
                src={a1}
                alt="Flooring, furniture and hardware needs"
                className="rounded items-center justify-center"
              />

            </div>
            <div class="col-md-9 col-sm-9 col-9 p-1 col-9" >
              <section>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{
                    paddingInline: '5% 10%'
                  }}>
                    <div className='jumbotron pt-4' >
                      <p class="font-bold text-[22px]">MICHAEL ENRIGHT- OWNER</p>
                      <p class=" text-[16px] pt-2 font-semibold">"I am passionate about helping people to succeed in life. Business to me is about being a trusted organization, meeting our commitments, and supporting our people and customers."</p>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Experience</p>
                      <li class=" text-[16px] ">
                      15 plus years in the security and fire industry, received an MBA from Crummer Graduate School of Business                      </li>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Favorite Things to Do</p>
                      <li class=" text-[16px] ">
                      I love being with family, and if it involves a score it’s even better. I’m a loyal FSU sports fan, would love to travel more, and I enjoy mentoring people as they navigate life’s challenges.                        </li>
                      

                    </div>

                  </div>
                </div>
              </section >

            </div>
          </div>
        </div>
      </section>
      <section className=' border-t border-slate-700 px-[5%] mt-5'>
        <div className='pt-4'>
          <div class="row flex content-center">
            <div class="col-md-3 col-sm-3 col-3">
              <img
                src={a2}
                alt="Flooring, furniture and hardware needs"
                className="rounded items-center justify-center"
              />

            </div>
            <div class="col-md-9 col-sm-9 col-9 p-1 col-9" >
              <section>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{
                    paddingInline: '5% 10%'
                  }}>
                    <div className='jumbotron pt-4' >
                      <p class="font-bold text-[22px]">CORBAN CLINCKSCALE- DIRECTOR OF FIELD OPERATIONS</p>
                      <p class=" text-[16px] pt-2 font-semibold">"Teaching and learning are incredibly powerful tools that we can use to better ourselves and those around us, in meaningful ways. It’s something we can do both personally and professionally to enrich the lives around us."</p>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Experience</p>
                      <li class=" text-[16px] ">
                      Have worked in the security and fire industry since 2013, and State Certified with a Low Voltage License                      </li>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Favorite Things to Do</p>
                      <li class=" text-[16px] ">
                      I love spending time with my beautiful wife and two bulldogs. I’m a Boston sports fan, and keep busy reading, playing basketball, board games, and fantasy football.                        </li>
                      

                    </div>

                  </div>
                </div>
              </section >

            </div>
          </div>
        </div>
      </section>
      <section className=' border-t border-slate-700 px-[5%] mt-5'>
        <div className='pt-4'>
          <div class="row flex content-center">
            <div class="col-md-3 col-sm-3 col-3">
              <img
                src={a3}
                alt="Flooring, furniture and hardware needs"
                className="rounded items-center justify-center"
              />

            </div>
            <div class="col-md-9 col-sm-9 col-9 p-1 col-9" >
              <section>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{
                    paddingInline: '5% 10%'
                  }}>
                    <div className='jumbotron pt-4' >
                      <p class="font-bold text-[22px]">JOYCE GRAVES - OFFICE ADMINISTRATOR</p>
                      <p class=" text-[16px] pt-2 font-semibold">“I believe that every choice we make and every decision we make matters. I could not be working with a greater group of people who care about the work they do.”</p>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Experience</p>
                      <li class=" text-[16px] ">
                      6 plus years in the security and fire industry, over 30 years in office administration                      </li>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Favorite Things to Do</p>
                      <li class=" text-[16px] ">
                      I thoroughly enjoy reading, singing, traveling, and seeing our great country.                        </li>
                      

                    </div>

                  </div>
                </div>
              </section >

            </div>
          </div>
        </div>
      </section>
      <section className=' border-t border-b border-slate-700 px-[5%] mt-5'>
        <div className='py-4'>
          <div class="row flex content-center">
            <div class="col-md-3 col-sm-3 col-3">
              <img
                src={a4}
                alt="Flooring, furniture and hardware needs"
                className="rounded items-center justify-center"
              />

            </div>
            <div class="col-md-9 col-sm-9 col-9 p-1 col-9" >
              <section>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{
                    paddingInline: '5% 10%'
                  }}>
                    <div className='jumbotron pt-4' >
                      <p class="font-bold text-[22px]">TOM LAUGHREY- SENIOR ALARM TECHNICIAN</p>
                      <p class=" text-[16px] pt-2 font-semibold">"If it's worth doing, it's worth doing right"</p>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Experience</p>
                      <li class=" text-[16px] ">
                      Over 40 plus years in the alarm industry                      </li>
                      <p  class=" text-[18px] pt-2 font-bold underline"> Favorite Things to Do</p>
                      <li class=" text-[16px] ">
                      I love my Cleveland Browns and enjoy anything that’s sports-related.                        </li>
                      

                    </div>

                  </div>
                </div>
              </section >

            </div>
          </div>
        </div>
      </section>

      <div><Footer /> </div>
    </div>
  )
}

export default About
