import React from 'react'
import home7 from './Component/images/newindex3.png';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Form from './Component/requestestimate2';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
       
			<section >
				<img
										src={home7}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%]"
									/>
									<div className="container mx-auto mt-8">
      <p className="text-[35px] font-bold mb-4 text-center">Smart home automation is now affordable and easy to use.</p>
      <p className="mb-4 text-[20px] text-center">
	  Smart home automation can save you time and money by automating routine tasks, like turning off lights and adjusting thermostats, and optimizing energy usage for lower utility bills. Enjoy a more efficient and cost-effective lifestyle with smart home technology.   </p>
    </div>
      <div className="container mx-auto mt-8 mb-4">
      
      <li className='text-[20px]'>Real-time awareness of anything going on, in and around your home </li>
      <li className='text-[20px]'>A connectedness to loved ones and pets when you are away</li>
      <li className='text-[20px]'>The convenience of having answers at your fingertips</li>
      <li className='text-[20px]'>The ability to lock a front door or shut the garage from your phone</li>
      <li className='text-[20px] mb-4'>Verified reports through camera and video</li>
	  <p className="text-2xl font-bold mb-4 text-center ">Enjoy the convenient one-touch control you have when Smart Technology intersects with the details of everyday living. </p>
    <div className='item-center justify-center flex'>
	  <iframe width="835" height="469" src="https://www.youtube.com/embed/BsZ45Hwlm88" title="Products and Services Overview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    </div>
  </div>

			</section>

    
   
    <div><Form /></div>
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
