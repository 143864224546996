import React from 'react'
import Home from '../Home'
import {BrowserRouter , Routes, Route} from 'react-router-dom'
import NotFound from '../PageNotFound.jsx'
import FreeEstimate from '../FreeEstimate.jsx';

import Services from '../Services.jsx'
import Specials from '../Special.jsx'
import Contact from '../Contact.jsx'
import Reviews from '../Reviews.jsx'
import Financing from '../Financing.jsx'
import About from '../About.jsx'
import Hardware from '../Hardware.jsx';
import Furniture from '../Furniture.jsx';
import Credit from '../Credit.jsx';
import Form from '../Form.jsx';
import ACH from '../ACH.jsx';
const AllRoutes = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/specials' element={<Specials/>}></Route>
      

      <Route path='/fire' element={<Furniture/>}></Route>
      <Route path='/Security' element={<Hardware/>}></Route>



      <Route path='/homesecurity' element={<Services/>}></Route>
      <Route path='/homecontrol' element={<Reviews/>}></Route>
      <Route path='/builder' element={<Financing/>}></Route>
      <Route path='/about-us' element={<About/>}></Route>
      <Route path='/contact' element={<Contact/>}></Route>
      <Route path='/free-estimate' element={<FreeEstimate/>}></Route>
      <Route path='/credit' element={<Credit/>}></Route>
      <Route path='/ACH' element={<ACH/>}></Route>
      <Route path='/form' element={<Form/>}></Route>

      
      
      <Route path="*" element={<NotFound />} />
    </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
