import React from 'react';
import { io } from 'socket.io-client';

const URL = 'https://socket.salescaptain.com';

export const socket = io(URL,{
  transports: ['websocket'],
});

export const SocketContext = React.createContext();
