import React from 'react'
import home5 from './Component/images/newindex1.png';
import home6 from './Component/images/newindex2.png';
import home7 from './Component/images/newindex3.png';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Form from './Component/requestestimate2';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
        <section >
				<img
										src={home5}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%]"
									/>
									<div className="container mx-auto mt-8">
      <p className="text-[35px] font-bold mb-4 text-center">Home security and technology doesn't have to be overwhelming.</p>
      <p className="mb-4 text-[20px] text-center">
	  Protect your Orlando home with our advanced home security solutions powered by Alarm.com. Our smart home technology offers real-time alerts, 24/7 monitoring, and remote access to keep you connected and secure. Trust us to keep your family and property safe with our reliable and customizable security systems.     </p>
    </div>
      <div className="container mx-auto mt-8 mb-4">
      
      <li className='text-[20px]'>24/7 Professional Monitoring Centers </li>
      <li className='text-[20px]'> Emergency Call Buttons</li>
      <li className='text-[20px]'>The ability to arm or disarm the security system from any location</li>
      <li className='text-[20px]'>Dispatching of emergency personnel </li>
      <li className='text-[20px] mb-4'> Real-time notifications of security breaches</li>
	  <p className="text-2xl font-bold mb-4 text-center ">Rest in the knowledge that the security of your home and family is always our priority. </p>
	  <div className='item-center justify-center flex'>


	  <iframe width="835" height="469" src="https://www.youtube.com/embed/FKrQ1h2z66E" title="Smart Signal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
	</div>

			</section>
			<section >
				<img
										src={home7}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%]"
									/>
									<div className="container mx-auto mt-8">
      <p className="text-[35px] font-bold mb-4 text-center">Smart home automation is now affordable and easy to use.</p>
      <p className="mb-4 text-[20px] text-center">
	  Smart home automation can save you time and money by automating routine tasks, like turning off lights and adjusting thermostats, and optimizing energy usage for lower utility bills. Enjoy a more efficient and cost-effective lifestyle with smart home technology.   </p>
    </div>
      <div className="container mx-auto mt-8 mb-4">
      
      <li className='text-[20px]'>Real-time awareness of anything going on, in and around your home </li>
      <li className='text-[20px]'>A connectedness to loved ones and pets when you are away</li>
      <li className='text-[20px]'>The convenience of having answers at your fingertips</li>
      <li className='text-[20px]'>The ability to lock a front door or shut the garage from your phone</li>
      <li className='text-[20px] mb-4'>Verified reports through camera and video</li>
	  <p className="text-2xl font-bold mb-4 text-center ">Enjoy the convenient one-touch control you have when Smart Technology intersects with the details of everyday living. </p>
	  <div className='item-center justify-center flex'>

	  <iframe width="835" height="469" src="https://www.youtube.com/embed/BsZ45Hwlm88" title="Products and Services Overview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	</div>
	</div>

			</section>
			<section >
				<img
										src={home6}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%]"
									/>
									<div className="container mx-auto mt-8">
      <p className="text-[35px] font-bold mb-4 text-center">For over 25 years, H&W Alarm has worked alongside area builders to provide quality low-voltage, security services, and smart home technology to their clients.</p>
      <p className="mb-4 text-[20px] ">
	  With over 3,000 custom home installs, we know what is needed for the builder and the homeowner. Our exceptional service reflects a desire to continue working with satisfied customers long after the home is finished and warranties have ended.   </p>
    
	  <p className="text-2xl font-bold mb-4  ">For today’s homeowners, technology is no longer an option, but a requirement. H&W Alarm promotes SIMPLE technology and solutions that support the modern family’s lifestyle. </p>
	  <p className="mb-4 text-[20px] ">
	  Our straightforward builder program will help YOU to stay on budget and deliver the anticipated technology. We also educate your future homeowners on:  </p>
    
</div>
      <div className="container mx-auto mt-8 mb-4">
      
      <li className='text-[20px]'>Streaming Service </li>
	  <li className='text-[20px]'>Cable Options </li>
	  <li className='text-[20px]'> Connectivity</li>
	  <li className='text-[20px]'>Simple Whole House Audio </li>
	  <li className='text-[20px]'>Camera Solutions </li>
	  <li className='text-[20px]'> Security Solutions</li>
	  <li className='text-[20px]'>Intelligent Lighting </li>
	  <li className='text-[20px]'>Vac Dust Pans </li>
	  <li className='text-[20px]'>Video Doorbells </li>
	  <li className='text-[20px]'>State of the Art Mesh Networks </li>
      <li className='text-[20px] mb-4'> Fully Connected Home With NO Dead Spots</li>
	  <div className='item-center justify-center flex'>

	  <iframe width="835" height="469" src="https://www.youtube.com/embed/dgS8yjjJTG8" title="Smart Signal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	</div>
	</div>

			</section>
    
   
    <div><Form /></div>
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
