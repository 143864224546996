/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable object-curly-newline */
import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AiOutlineDollarCircle, AiOutlineSend, AiOutlineHistory } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import { Button, TextField } from '@mui/material';
import { BiMessageDetail } from 'react-icons/bi';
import ChatItem from './ChatItem1';
import { SocketContext } from './socket';
import myImage from './Component/image.png';

function WebchatDesign2() {
  const [value, setValue] = useState('');
  const baseURL = 'https://app.salescaptain.com/api/v1';
  const [messages, setMessages] = useState([]);
  const socket = useContext(SocketContext);
  const [messageForSend, setMessageForSend] = useState(null);
  const [loadingText, setLoadingText] = useState(false);
  const [convoId, setConvoId] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [companyId, setCompanyId] = useState('cad333a7-2f8a-4f38-be3e-98a28ae3775f');
  const [getSuggestionList, setSuggestions] = useState([]);
  useEffect(() => {
    handleGetAllSuggestion();
  },[]);
  const handleGetAllSuggestion = async () => {
    // console.log(multilocationId);
    try {
      const response = await axios.get(`${baseURL}/suggestion/get-all/${companyId}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        }
      });
      setSuggestions(response?.data?.data?.entity);
    } catch (err) {
      console.log('handleGetWebChatSettings err', err);
    }
  };

  const sendUserMessage = async () => {
    setMessages((prev) => [...prev, { id: uuidv4(), author: 'userName', msg: messageForSend, msgType: 'client', message_id: uuidv4() }]);

    setLoadingText(true);
    setMessageForSend('');
    SendingDataToAI(messageForSend);
  };
  const SendingDataToAI = async (userMessage) => {
    const params = { company_id: companyId, multilocation_id: '' };
    const route = 'open_ai.get_suggestion_answer';
    const body = {
      company_id: companyId,
      multilocation_id: '',
      conversation_id: uuidv4(),
      suggestion: userMessage,
      number: 'userNumber',
      name: 'userName',
      test_chat_key: true,
    };
    setConvoId(body.conversation_id);
    socket.emit('suggestion-ai', { route, body, params });
  };
  useEffect(() => {
    function handleActivity(value1) {
      const data = JSON.parse(value1);
      // console.log(data);
      const lastMessageIndex = messages.findIndex((message) => message.message_id === data.message_id);
      if (data.route === 'open_ai.get_suggestion_answer.response' && convoId === data.conversation_id) {
        if (lastMessageIndex !== -1) {
          setMessages((prev) => {
            const updatedMessages = [...prev];
            const containsNonAlphabets = !/^[a-z]+$/.test(data.message_content);
            updatedMessages[lastMessageIndex] = {
              ...updatedMessages[lastMessageIndex],
              msg: updatedMessages[lastMessageIndex].msg + data.message_content,
            };
            return updatedMessages;
          });
        } else {
          setMessages((prev) => [
            ...prev,
            {
              id: uuidv4(),
              author: 'name',
              msg: data.message_content,
              msgType: 'server',
              message_id: data.message_id,
            },
          ]);
        }
        setLoadingText(false);
      }
      if (data.route === 'open_ai.get_suggestion_answer.error_response' && convoId === data.conversation_id) {
        setLoadingText(false);
        setMessages((prev) => [
          ...prev,
          {
            id: uuidv4(),
            author: 'name',
            msg: 'Fail to get response',
            msgType: 'server',
            message_id: data?.message_id,
          },
        ]);
      }
    }
    socket.on('suggestion-ai', handleActivity);
    return () => {
      socket.off('suggestion-ai', handleActivity);
    };
  }, [messages,convoId]);

  const setDefaultMessage = async (message) => {
    setMessageForSend(message);
    await setMessages((prev) => [...prev, { id: uuidv4(), author: 'userName', msg: message, msgType: 'client', message_id: uuidv4() }]);

    setLoadingText(true);
    setMessageForSend('');
    SendingDataToAI(message);
  };
  const messageEndRef = useRef();
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  })

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setDefaultMessage(messageForSend);
    }
  };
  return (
    <div className="h-full  rounded-md w-[100%] bg-white sm:mx-7 mx-2">
      <div
        className=" flex flex-row   text-[12px] pt-3 w-[100%] ml-4 rounded-t-lg h-fit border-b border-slate-200"
      >
        <img
          src={myImage}
          className="flex flex-row   cursor-pointer h-[40px] w-fit  justify-start items-center"
          alt="webchat"
        />
        
        <div>
          <p className="font-bold text-[18px] h-[40px] pl-3 flex text-slate-900 justify-start items-center">
            H&W Alarm
          </p>
        </div>
      </div>
      <div className="bg-[#fff]  flex flex-col   text-[15px] p-3  h-[350px] overflow-y-auto overflow-x-hidden">
        {messages?.map((m) => (
          <ChatItem message={m} />
        ))}
        {loadingText && (
          <div className="flex px-1">
            <img
              src="https://s3.us-east-2.amazonaws.com/sc-prod-embedded.salescaptain.com/resources/2023-07-22/sales_captain_1690015901642.png"
              className="flex flex-row shadow-gray-400 shadow rounded-full cursor-pointer h-[30px] w-[30px] object-cover justify-start items-center"
              alt="webchat"
            />
            <div className="flex space-x-2  self-start break-words w-[85px]  bg-[#EEE] px-[8px] text-black text-[13px] sm:text-[12px]  py-[13px] my-[4px] ml-[24px]  rounded-t-[13px] rounded-br-[13px]">

              <span className="loaderWebchatLive"></span>
            </div>
          </div>
        )} 
        <div ref={messageEndRef} />
      </div> 
      <div className="suggestions bg-white self-start flex flex-row pb-1.5 h-fit  text-[12px] w-[100%]  overflow-x-auto">
        <div
          className="  suggestions flex flex-row text-[14px] w-fit  text-slate-800 rounded   cursor-pointer  "
        >
          {getSuggestionList?.map((data) => (
            <div
              className=" flex flex-row text-[12px] w-fit  text-slate-800 rounded px-1.5 py-1 cursor-pointer suggestions"
            >
              <span 
                className="bg-[#EEE] hover:bg-slate-300 p-1.5 rounded-lg w-full whitespace-nowrap" 
                onClick={() => {
                  setDefaultMessage(data?.question);
                }}
              >
                {data?.question}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-[#fff] shadow-t-2xl relative  justify-center flex flex-row items-center border-t border-slate-200  text-[12px] w-[100%] h-fit ">
        <textarea
          placeholder="Enter Your Support Message Here....          "
          value={messageForSend}
          onChange={(e) => setMessageForSend(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            paddingTop: '8px',
            color: 'black',
            paddingLeft: '15px',
            fontSize: '17px',
            width: '100%',
            resize: 'none',
            border: 'none',
            overflow: 'none',
            outline: 'none',
            height: '100%',
            overflowY: 'hidden',
            // backgroundColor: 'red', 
          }}
        // onSelect={handleSelect} 
        />
        <button
          type="button"
          className="h-[36px] flex items-center justify-center w-fit
                   rounded-md text-slate-800 cursor-pointer
                  ml-[20px] mr-[20px]"
          onClick={sendUserMessage}
          disabled={messageForSend === null || messageForSend === ''}
        >
          <AiOutlineSend size={21} />
        </button>
      </div>
      <div className=" bg-[#fff] shadow-t-2xl relative  justify-center flex flex-row items-center   text-[14px] w-[100%] rounded-b-lg h-fit p-2 ">
        <span>Powered by</span>
        <img src="https://s3.us-east-2.amazonaws.com/sc-prod-embedded.salescaptain.com/resources/2023-07-22/sales_captain_1690015901642.png" alt="logo not found" className="h-[1rem] w-[1rem] rounded-full mr-1 ml-1" />
        <span className="font-black text-black medium">SalesCaptain</span>
      </div>
    </div>
  );
}

export default WebchatDesign2;
