import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import mainhome from './Component/images/Sample+heading+(1).png';
import mainhome1 from './Component/images/Sample+heading.png';


function Home() {
	// const t = "LM12";

	return (
		<div >

			<Headers />
			{/* <div> <a href='/contact'> <img src= {require('./Component/photo/' + t + '.png')} alt="Los Angeles" class="d-block img-fluid" /></a></div> */}
			

			{/* <section >
			<img
										src={mainhome}
										alt="rr"
										className="mx-auto w-full pb-3"
									/>
			</section> */}
			<section>
				<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active" data-bs-interval="3000">
							<img
								src={mainhome1}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto w-full pb-3"
							/>
						</div>
						<div class="carousel-item active" data-bs-interval="3000">
							<img
								src={mainhome}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto w-full pb-3"
							/>
						</div>
					</div>
					<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
				</div>
				
			</section>
		


			

			<div><Request /></div>
			
			<div><Footer /></div>


		</div>
	);
}

export default Home;
