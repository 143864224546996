import React, { useState } from 'react'
import myImage from './image.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div>

			<div class="bg-white ">
				<div class="container px-2">

					<div class="flex items-center justify-between pt-4 pb-2">

						<div class="hidden sm:flex sm:items-center">
						</div>

						<div className=" d-flex justify-content-center " >
							<a href="/" class="d-block" style={{ height: '35%', width: '70%' }} ><img src={myImage} alt="Los Angeles" /> </a>

						</div>



						<div class="hidden sm:flex sm:items-center">
						</div>


					</div>
					<div class="flex items-center lg:order-2 pb-2 px-3">
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>



					<div
						className={`nav-links duration-500 md:static absolute  text-slate-700   left-0 ${menuOpen ? 'top-[9%] bg-white relative' : 'bg-white top-[-100%] items-center justify-content-center absolute'
							} md:w-auto w-full flex  `} 
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2  ">

						<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-slate-700  font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
								<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							<li className='pl-2 py-1'>
								<a href="/about-us" class="text-slate-700  btn px-3">About</a>
							</li>
							
							<li className='nav-item pl-2'>
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-slate-700"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Residential
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/homesecurity" class=" dropdown-item hover:text-green-900 ">Home Security</a>
										</li>
										<li>
											<a href="/homecontrol" class=" dropdown-item hover:text-green-900 ">Home Control</a>
										</li>
										<li>
											<a href="/builder" class=" dropdown-item hover:text-green-900 ">Builder Services</a>
										</li>
										
										
									</ul>
								</div>
							</li>
							<li className='nav-item pl-2'>
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-slate-700"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Commercial
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/fire" class=" dropdown-item hover:text-green-900 ">Fire</a>
										</li>
										<li>
											<a href="/Security" class=" dropdown-item hover:text-green-900 ">Security</a>
										</li>
										
										
									</ul>
								</div>
							</li>
							<li className='pl-2 py-1'>
								<a href="/specials" class="text-slate-700  btn px-3">Support</a>
							</li>
							<li className='pl-2 py-1'>
								<a href="/contact" class="text-slate-700  btn px-3">Service Request</a>
							</li>
							<li className='pl-2 py-1'>

								<a href="https://clienthub.getjobber.com/client_hubs/a1f130a0-dc13-4d8e-b7ca-299a85006783/login/new?source=share_login"class="btn btn-primary btn active" role="button" style={{ backgroundColor: '#44723b', borderColor: '#44723b', borderRadius: '8px' }}>CLIENT HUB</a>
							</li>
							<li className='pl-2 py-1'>
								<a href="https://hwalarm.alarmbiller.com/Dealer/DealerAccount/Login" class="btn btn-primary btn active" role="button" style={{ backgroundColor: '#44723b', borderColor: '#44723b', borderRadius: '8px' }}>PAY MONITORING BILL</a>
							</li>


						</ul>
					</div>
				</div>




			</div>


		</div>
	)
}

export default Header
