import { useEffect, useRef, useState } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import p1 from './Component/pdf/CUSTOMER+PORTAL+SETUP+INSTRUCTIONS.pdf';
import p2 from './Component/pdf/DSC-trouble-guide.pdf';
import p3 from './Component/pdf/PC1555-EN+(1).pdf';
import p4 from './Component/pdf/PS-Neo_HS2016-32-64-128_v1-0_Reference-Manual__Extended_R001_en+(1).pdf';
import p5 from './Component/pdf/IQ-Panel-2-User-Guide.pdf';
import p6 from './Component/pdf/CNTRL2-Operation-and-Users-Guide1.pdf';
import p7 from './Component/pdf/UsingAlarmdotcom.pdf';
import { socket, SocketContext } from './socket';
import WebchatDesign2 from "./WebchatLive";

function Special() {

  return (
    <SocketContext.Provider value={socket}>
      <div>
        <div><Headers /></div>
        <div className="flex justify-center items-center sm:w-[98%] w-[95%] my-2"><WebchatDesign2 /></div>
        {/* <div className='px-5 py-3'>
          <iframe
            src="https://www.chatbase.co/chatbot-iframe/r-x2kBV7sJPRWRbMmrbVd?wmode=opaque"
            width="100%"
            data-embed="true"
            style={{ height: '100%', minHeight: '700px' }}
            frameBorder="0"
            title='hi'
          ></iframe>
        </div> */}
        <div className="sqs-html-content text-center ">
          <br></br>
          <h2 className="text-center ">H&W Alarm Support Links If You Know Your Specific Problem</h2>
          <h3>
            <a href={p1} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">
              Customer Portal Set Up Instructions (PDF)
            </a>
          </h3>
          <br></br>
          <p className=""></p>

          <h1 className="">Videos to Help With Common Support Questions</h1>
          <br></br>
          <h3>
            <a href="https://youtu.be/Zk8HNo6s9-A" target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">How to Set a Date and Time on a DSC Panel</a>
          </h3>
          <h3>
            <a href="https://www.youtube.com/watch?feature=youtu.be&amp;v=yTjkWZVkIXA" target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">How to Bypass a Zone on DSC</a>
          </h3>
          <h3>
            <a href="https://youtu.be/SPTpRT4CReo" target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">What is wrong with my alarm? (Trouble codes)</a>
          </h3>
          <h3>
            <a href="https://youtu.be/qgIM9OcRHj0" target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">How to Use Your DSC Security System</a>
          </h3>
          <p className="whitespace-pre-wrap"></p>
          <br></br>
          <h1 className="whitespace-pre-wrap">Panel User Guides and Trouble Codes (PDF)</h1>
          <br></br>
          <h3>
            <a href={p2} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">DSC Trouble Codes</a>
          </h3>
          <h3>
            <a href={p3} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">DSC PC1555 User Guide (Older Model DSC)</a>
          </h3>
          <h3>
            <a href={p4} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">DSC Neo User Guide</a>
          </h3>
          <h3>
            <a href={p5} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">Qolsys IQ Panel User Guide</a>
          </h3>
          <h3>
            <a href={p6} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">2GIG User Guide</a>
          </h3>
          <h3>
            <a href={p7} target="_blank" rel="noreferrer" className="underline text-slate-500 font-normal">ALARM.com User Guide</a>
          </h3>
        </div>
        <br></br>
        <div><Footer /></div>
      </div>
    </SocketContext.Provider>
  )
}

export default Special
