import React from 'react'

function extractLinkFromText(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  const match = text.match(urlRegex);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}
function extractLinkFromText1(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  return text.replace(urlRegex, '');
}
function MessageBubble({ message }) {
  const link = extractLinkFromText(message?.msg);
  
  return (
    <div className='flex flex-col pt-[12px] w-[100%] bg-white'> 
      {message?.msgType !== 'client' ?
        (
          <div className='flex flex-row '>
            <span className="px-3 flex justify-end py-[8px] items-end rounded-full bg-white ">
              <img
              src="https://s3.us-east-2.amazonaws.com/sc-prod-embedded.salescaptain.com/resources/2023-07-22/sales_captain_1690015901642.png"
              className='flex flex-row shadow-gray-400 shadow rounded-full cursor-pointer h-[30px] w-[30px] object-cover justify-start items-center'
                alt='webchat'
              />
            </span>
            <div className={`self-start break-words flex ${link ? `max-w-[100%]` : `max-w-[70%]`}  w-fit bg-[#EEE] px-[16px] text-black text-[14px]  pt-[8px] my-[4px]   rounded-t-[16px] rounded-br-[16px]`}>

              <p>{extractLinkFromText1(message?.msg)}
              {link && (
                <a href={link} className="text-blue-600 hover:text-blue-700" target="_blank" rel="noopener noreferrer">{link}</a>
              )}
              </p>
            </div>
          </div>
        ) : (
          <div className='self-end break-words  w-fit max-w-[70%] bg-[#44723b] text-white border shadow-md   px-[16px] sm:text-[14px]  text-[14px]  pt-[8px] my-[4px] mr-[16px]  rounded-t-[16px] rounded-bl-[16px]'>
            <p>{message?.msg}</p>
          </div>
        )}

    </div>
  )
}

export default MessageBubble
