import React from 'react'
import home5 from './Component/images/newindex11.png';
import home6 from './Component/images/newindex12.png';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Form from './Component/requestestimate2';
import i1 from './Component/images/i1.png';
import i2 from './Component/images/i2.png';
import i3 from './Component/images/i3.png';
import i4 from './Component/images/i4.png';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
        <section >
				<img
										src={home5}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%]"
									/>

			</section>
    <div className="container mx-auto mt-8">
      <p className="text-2xl font-bold mb-4 text-center">One small spark could destroy the properties you’ve worked hard to maintain.</p>
      <p className="mb-4 text-[20px] text-center">
      Put our 50-plus years of collective experience to work for your business. Our commitment to being your trusted ally is unmatched.      </p>
    </div>
    <section className=' border-t border-slate-700 container mt-5'>
    <div className='pt-4'>
					<div class="row flex content-center">
						<div class="col-md-3 col-sm-3col-3">
            <img
										src={i1}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto rounded items-center justify-center pt-[15%]"
									/>

						</div>
						<div class="col-md-9 col-sm-9 col-9 p-1 col-9 pr-4" >
            <section>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="font-bold text-[22px]">Monitoring</p>
              <p class=" text-[20px] pt-2">You need to be able to count on your equipment and systems to keep your assets, properties, and people safe 24/7.</p>
              <p class=" text-[20px] pt-2">That’s why at H&W Alarm we strive to give you the HIGHEST level of professional detection and monitoring. We understand that a fast response is critical to limiting your losses. If a fire signal is detected we dispatch experienced and qualified emergency personnel to your property immediately.
              </p>

            </div>

					</div>
				</div>
			</section >

						</div>
					</div>
				</div>
    </section>
    <section className=' border-t border-slate-700 container mt-5'>
    <div className='pt-4'>
					<div class="row flex content-center">
						<div class="col-md-3 col-sm-3col-3">
            <img
										src={i2}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto rounded items-center justify-center pt-[15%]"
									/>

						</div>
						<div class="col-md-9 col-sm-9 col-9 p-1 col-9 pr-4" >
            <section>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="font-bold text-[22px]">Inspections</p>
              <p class=" text-[20px] pt-2">
              Whether you own a large company, you have a small business, or you’re part of a non-profit, we can help you meet the requirement of mandatory inspections.                </p>
              <p class=" text-[20px] pt-2">
              Our certified team of specialists has worked with the different brands found in commercial, industrial, and manufacturing properties.              </p>

            </div>

					</div>
				</div>
			</section >

						</div>
					</div>
				</div>
    </section>
    <section className=' border-t border-slate-700 container mt-5'>
    <div className='pt-4'>
					<div class="row flex content-center">
						<div class="col-md-3 col-sm-3col-3">
            <img
										src={i3}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto rounded items-center justify-center pt-[15%]"
									/>

						</div>
						<div class="col-md-9 col-sm-9 col-9 p-1 col-9 pr-4" >
            <section>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="font-bold text-[22px]">Service</p>
              <p class=" text-[20px] pt-2">Our trained personnel are qualified to keep your equipment performing optimally. </p>
              <p class=" text-[20px] pt-2">Whether you are looking for routine maintenance or your equipment is in need of repairs, we have the knowledge and expertise to diagnose and address the issues that may arise. Our reasonable rates and flexible scheduling enable you to get back to business as soon as possible.
              </p>

            </div>

					</div>
				</div>
			</section >

						</div>
					</div>
				</div>
    </section>
    <section className=' border-t border-slate-700 container mt-5'>
    <div className='pt-4'>
					<div class="row flex content-center">
						<div class="col-md-3 col-sm-3col-3">
            <img
										src={i4}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto rounded items-center justify-center pt-[15%]"
									/>

						</div>
						<div class="col-md-9 col-sm-9 col-9 p-1 col-9 pr-4" >
            <section>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="font-bold text-[22px]">Installation</p>
              <p class=" text-[20px] pt-2">We approach every installation of alarms and smart detection devices with these important factors top-of-mind:</p>
              <li class=" text-[20px] pt-2">
              Compliance 
              </li>
              <li class=" text-[20px] pt-2">
              Convenient and timely scheduling 
              </li>
              <li class=" text-[20px] pt-2">
               Best solutions for YOUR specific circumstances
              </li>
              

            </div>

					</div>
				</div>
			</section >

						</div>
					</div>
				</div>
    </section>
    <section >
				<img
										src={home6}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%] pt-5"
									/>

			</section>
      <div className="container mx-auto mt-8 mb-4">
      <p className="text-2xl font-bold mb-4 text-center">You need the assurance that your security systems offer your employees and your properties the highest level of protection. </p>
      <p className="mb-2 text-[20px] text-center">
      At H&W we don’t want it to ever feel complicated. Your business security should:  </p>
      <li className='text-[20px]'>Be automated </li>
      <li className='text-[20px]'> Be flexible and easy to use</li>
      <li className='text-[20px]'> Provide verifiable information through video monitoring</li>
      <li className='text-[20px]'>Give you connectivity with livestream video </li>
      <li className='text-[20px] mb-4'> Supply real-time notifications of deliveries, employee arrivals, or when secure rooms are accessed</li>
	  <div className='item-center justify-center flex'>

      <iframe width="835" height="469" src="https://www.youtube.com/embed/_kMidu9IEAE" title="H&amp;W Business Monitoring" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
    </div>
   
    <div><Form /></div>
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
