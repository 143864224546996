import React from 'react'
import home6 from './Component/images/newindex2.png';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Form from './Component/requestestimate2';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
        
			<section >
				<img
										src={home6}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto w-full h-[50%]"
									/>
									<div className="container mx-auto mt-8">
      <p className="text-[35px] font-bold mb-4 text-center">For over 25 years, H&W Alarm has worked alongside area builders to provide quality low-voltage, security services, and smart home technology to their clients.</p>
      <p className="mb-4 text-[20px] ">
	  With over 3,000 custom home installs, we know what is needed for the builder and the homeowner. Our exceptional service reflects a desire to continue working with satisfied customers long after the home is finished and warranties have ended.   </p>
    
	  <p className="text-2xl font-bold mb-4  ">For today’s homeowners, technology is no longer an option, but a requirement. H&W Alarm promotes SIMPLE technology and solutions that support the modern family’s lifestyle. </p>
	  <p className="mb-4 text-[20px] ">
	  Our straightforward builder program will help YOU to stay on budget and deliver the anticipated technology. We also educate your future homeowners on:  </p>
    
</div>
      <div className="container mx-auto mt-8 mb-4">
      
      <li className='text-[20px]'>Streaming Service </li>
	  <li className='text-[20px]'>Cable Options </li>
	  <li className='text-[20px]'> Connectivity</li>
	  <li className='text-[20px]'>Simple Whole House Audio </li>
	  <li className='text-[20px]'>Camera Solutions </li>
	  <li className='text-[20px]'> Security Solutions</li>
	  <li className='text-[20px]'>Intelligent Lighting </li>
	  <li className='text-[20px]'>Vac Dust Pans </li>
	  <li className='text-[20px]'>Video Doorbells </li>
	  <li className='text-[20px]'>State of the Art Mesh Networks </li>
      <li className='text-[20px] mb-4'> Fully Connected Home With NO Dead Spots</li>
	  <div className='item-center justify-center flex'>


	  <iframe width="835" height="469" src="https://www.youtube.com/embed/dgS8yjjJTG8" title="Smart Signal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	</div>
	</div>

			</section>
    
   
    <div><Form /></div>
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
