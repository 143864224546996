import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'></small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
              468 Newhearth cir, Winter Garden, FL, United States.
              </p>
              <a href="https://www.google.com/maps?cid=10805388336370172341" target="_blank" class="btn btn-primary btn-lg active d-flex justify-content-center" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(407)-696-4411</p>
            </div>

            <div className="mb-4">
              <a href="tel:+1tel:+14076964411" class="btn btn-primary btn-lg active d-flex justify-content-center" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }} >Call us</a>

            </div>
          </div>
        </div>

      </div>
     
      <div class="container pb-3">
        <div class="map-responsive">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13770164.80917423!2d-112.35145330962719!3d32.583347069040116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7838e4465ed4d%3A0x95f47371497e41b5!2sH%26W%20Alarm!5e0!3m2!1sen!2sin!4v1706640141195!5m2!1sen!2sin" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact
