import React from 'react'
import Headers from './Component/Header';
import Footer from './Component/Footer';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
       
			<section >
            <iframe src="https://hwalarm.na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhBVXwBsU0d-kCQJ9MVznS0UOaBDQHy0Nsst4x6U9V4Eyu8CJwE0ykmj-Esa5HljICY*&hosted=false" width="100%" height="600px" frameborder="0" title="hi" allowfullscreen></iframe>

			</section>

    
   
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
