import React from 'react'
import Headers from './Component/Header';
import Footer from './Component/Footer';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
       
			<section >
            <iframe src="https://hwalarm.na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhBYh53hQrZSztHAz9XN4zAJy1MTBxXJdu7qyMwlea2H2MpdCKhIn1jb56RNv0xMEqY*&hosted=false" width="100%" height="600px" frameborder="0" title="hi" allowfullscreen></iframe>

            </section>

    
   
    <div ><Footer /></div>
    </div>
  )
}

export default Hardware
