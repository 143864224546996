import React from 'react'
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Form from './Component/requestestimate2';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
        
      <div className="container mx-auto mt-8 mb-4">
      <p className="text-2xl font-bold mb-4 text-center">You need the assurance that your security systems offer your employees and your properties the highest level of protection. </p>
      <p className="mb-2 text-[20px] text-center">
      At H&W we don’t want it to ever feel complicated. Your business security should:  </p>
      <li className='text-[20px]'>Be automated </li>
      <li className='text-[20px]'> Be flexible and easy to use</li>
      <li className='text-[20px]'> Provide verifiable information through video monitoring</li>
      <li className='text-[20px]'>Give you connectivity with livestream video </li>
      <li className='text-[20px] mb-4'> Supply real-time notifications of deliveries, employee arrivals, or when secure rooms are accessed</li>
      <div className='item-center justify-center flex'>

      <iframe width="835" height="469" src="https://www.youtube.com/embed/_kMidu9IEAE" title="H&amp;W Business Monitoring" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
    </div>
   
    <div><Form /></div>
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
