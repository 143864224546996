import React from 'react';
import myImage from './image.png';

function Footer() {
  return (
    
    <div className="px-4 pt-3 lg:px-8 bg-[#44723b] text-white">
      <div className="grid gap-10 row-gap-6  sm:grid-cols-2 lg:grid-cols-4 container mx-auto">
        <div className="sm:col-span-2">
          <a href="/" className="block" style={{ height: '15%', width: '35%' }}>
            <img src={myImage} alt="Los Angeles" />
          </a>
        </div>
        <div className="p-2 pt-4 flex flex-wrap">
          <a href="/" className="text-white mb-1 mr-4">
            Home
          </a>
          <a href="/about-us" className="text-white mb-1 mr-4">
            Company
          </a>
          {/* Add the mb-1 class to add margin-bottom between links */}
          <a href="/contact" className="text-white mb-1 mr-4">
            Contact
          </a>
          
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-white">Social</span>
          <div className="flex items-center mt-1 space-x-3">
            <a
              href="https://www.facebook.com/hwalarm/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              href="https://twitter.com/Hwalarm"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <i className="bi bi-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/93160099/admin/"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse justify-between pt-2 lg:flex-row container mx-auto">
        <p className="text-sm text-white">
        H&W Alarm Copyright 2019 | All Rights Reserved
        </p>
        
      </div>
    </div>
  )
}

export default Footer;
